import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d6d52ad4 = () => interopDefault(import('../pages/aboutus/index.vue' /* webpackChunkName: "pages/aboutus/index" */))
const _738a3f1a = () => interopDefault(import('../pages/check/index.vue' /* webpackChunkName: "pages/check/index" */))
const _757464ea = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _053a4a3d = () => interopDefault(import('../pages/gallery/index.vue' /* webpackChunkName: "pages/gallery/index" */))
const _1f94c8ea = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _31539e37 = () => interopDefault(import('../pages/reservation/index.vue' /* webpackChunkName: "pages/reservation/index" */))
const _33e80c40 = () => interopDefault(import('../pages/service/index.vue' /* webpackChunkName: "pages/service/index" */))
const _220ee422 = () => interopDefault(import('../pages/testimony/index.vue' /* webpackChunkName: "pages/testimony/index" */))
const _3ba7111a = () => interopDefault(import('../pages/venue/index.vue' /* webpackChunkName: "pages/venue/index" */))
const _4edbd925 = () => interopDefault(import('../pages/gallery/index_old.vue' /* webpackChunkName: "pages/gallery/index_old" */))
const _b5658d5c = () => interopDefault(import('../pages/home/index_old.vue' /* webpackChunkName: "pages/home/index_old" */))
const _65d6a01f = () => interopDefault(import('../pages/reservation/index_old.vue' /* webpackChunkName: "pages/reservation/index_old" */))
const _24acfe84 = () => interopDefault(import('../pages/reservation/slug_old.vue' /* webpackChunkName: "pages/reservation/slug_old" */))
const _0a36ff48 = () => interopDefault(import('../pages/service/index_old.vue' /* webpackChunkName: "pages/service/index_old" */))
const _619fe982 = () => interopDefault(import('../pages/venue/index_old.vue' /* webpackChunkName: "pages/venue/index_old" */))
const _7fd14194 = () => interopDefault(import('../pages/reservation/addons/slug_old.vue' /* webpackChunkName: "pages/reservation/addons/slug_old" */))
const _37dbf15a = () => interopDefault(import('../pages/reservation/buyer/slug_old.vue' /* webpackChunkName: "pages/reservation/buyer/slug_old" */))
const _9a845ddc = () => interopDefault(import('../pages/reservation/confirmation/slug_old.vue' /* webpackChunkName: "pages/reservation/confirmation/slug_old" */))
const _005c0540 = () => interopDefault(import('../pages/reservation/invoice/slug_old.vue' /* webpackChunkName: "pages/reservation/invoice/slug_old" */))
const _49047fa2 = () => interopDefault(import('../pages/reservation/otp/slug_old.vue' /* webpackChunkName: "pages/reservation/otp/slug_old" */))
const _221eb727 = () => interopDefault(import('../pages/reservation/payment/slug_old.vue' /* webpackChunkName: "pages/reservation/payment/slug_old" */))
const _25d69fce = () => interopDefault(import('../pages/reservation/qrcode/slug_old.vue' /* webpackChunkName: "pages/reservation/qrcode/slug_old" */))
const _7c85a55b = () => interopDefault(import('../pages/reservation/ticket/slug_old.vue' /* webpackChunkName: "pages/reservation/ticket/slug_old" */))
const _0358514a = () => interopDefault(import('../pages/reservation/check/qrcode/_slug.vue' /* webpackChunkName: "pages/reservation/check/qrcode/_slug" */))
const _10234d28 = () => interopDefault(import('../pages/reservation/check/qrcodenopaid/_slug.vue' /* webpackChunkName: "pages/reservation/check/qrcodenopaid/_slug" */))
const _6baadbd9 = () => interopDefault(import('../pages/reservation/addons/_slug.vue' /* webpackChunkName: "pages/reservation/addons/_slug" */))
const _0ab49c5a = () => interopDefault(import('../pages/reservation/buyer/_slug.vue' /* webpackChunkName: "pages/reservation/buyer/_slug" */))
const _1a02a5ca = () => interopDefault(import('../pages/reservation/confirmation/_slug.vue' /* webpackChunkName: "pages/reservation/confirmation/_slug" */))
const _b0e6d8e6 = () => interopDefault(import('../pages/reservation/invoice/_slug.vue' /* webpackChunkName: "pages/reservation/invoice/_slug" */))
const _9bedeeea = () => interopDefault(import('../pages/reservation/otp/_slug.vue' /* webpackChunkName: "pages/reservation/otp/_slug" */))
const _059c8726 = () => interopDefault(import('../pages/reservation/payment/_slug.vue' /* webpackChunkName: "pages/reservation/payment/_slug" */))
const _1b788374 = () => interopDefault(import('../pages/reservation/qrcode/_slug.vue' /* webpackChunkName: "pages/reservation/qrcode/_slug" */))
const _c2f9351c = () => interopDefault(import('../pages/reservation/ticket/_slug.vue' /* webpackChunkName: "pages/reservation/ticket/_slug" */))
const _2f9cfcef = () => interopDefault(import('../pages/reservation/_slug.vue' /* webpackChunkName: "pages/reservation/_slug" */))
const _0b10dcba = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _d6d52ad4,
    name: "aboutus"
  }, {
    path: "/check",
    component: _738a3f1a,
    name: "check"
  }, {
    path: "/contact",
    component: _757464ea,
    name: "contact"
  }, {
    path: "/gallery",
    component: _053a4a3d,
    name: "gallery"
  }, {
    path: "/home",
    component: _1f94c8ea,
    name: "home"
  }, {
    path: "/reservation",
    component: _31539e37,
    name: "reservation"
  }, {
    path: "/service",
    component: _33e80c40,
    name: "service"
  }, {
    path: "/testimony",
    component: _220ee422,
    name: "testimony"
  }, {
    path: "/venue",
    component: _3ba7111a,
    name: "venue"
  }, {
    path: "/gallery/index_old",
    component: _4edbd925,
    name: "gallery-index_old"
  }, {
    path: "/home/index_old",
    component: _b5658d5c,
    name: "home-index_old"
  }, {
    path: "/reservation/index_old",
    component: _65d6a01f,
    name: "reservation-index_old"
  }, {
    path: "/reservation/slug_old",
    component: _24acfe84,
    name: "reservation-slug_old"
  }, {
    path: "/service/index_old",
    component: _0a36ff48,
    name: "service-index_old"
  }, {
    path: "/venue/index_old",
    component: _619fe982,
    name: "venue-index_old"
  }, {
    path: "/reservation/addons/slug_old",
    component: _7fd14194,
    name: "reservation-addons-slug_old"
  }, {
    path: "/reservation/buyer/slug_old",
    component: _37dbf15a,
    name: "reservation-buyer-slug_old"
  }, {
    path: "/reservation/confirmation/slug_old",
    component: _9a845ddc,
    name: "reservation-confirmation-slug_old"
  }, {
    path: "/reservation/invoice/slug_old",
    component: _005c0540,
    name: "reservation-invoice-slug_old"
  }, {
    path: "/reservation/otp/slug_old",
    component: _49047fa2,
    name: "reservation-otp-slug_old"
  }, {
    path: "/reservation/payment/slug_old",
    component: _221eb727,
    name: "reservation-payment-slug_old"
  }, {
    path: "/reservation/qrcode/slug_old",
    component: _25d69fce,
    name: "reservation-qrcode-slug_old"
  }, {
    path: "/reservation/ticket/slug_old",
    component: _7c85a55b,
    name: "reservation-ticket-slug_old"
  }, {
    path: "/reservation/check/qrcode/:slug?",
    component: _0358514a,
    name: "reservation-check-qrcode-slug"
  }, {
    path: "/reservation/check/qrcodenopaid/:slug?",
    component: _10234d28,
    name: "reservation-check-qrcodenopaid-slug"
  }, {
    path: "/reservation/addons/:slug?",
    component: _6baadbd9,
    name: "reservation-addons-slug"
  }, {
    path: "/reservation/buyer/:slug?",
    component: _0ab49c5a,
    name: "reservation-buyer-slug"
  }, {
    path: "/reservation/confirmation/:slug?",
    component: _1a02a5ca,
    name: "reservation-confirmation-slug"
  }, {
    path: "/reservation/invoice/:slug?",
    component: _b0e6d8e6,
    name: "reservation-invoice-slug"
  }, {
    path: "/reservation/otp/:slug?",
    component: _9bedeeea,
    name: "reservation-otp-slug"
  }, {
    path: "/reservation/payment/:slug?",
    component: _059c8726,
    name: "reservation-payment-slug"
  }, {
    path: "/reservation/qrcode/:slug?",
    component: _1b788374,
    name: "reservation-qrcode-slug"
  }, {
    path: "/reservation/ticket/:slug?",
    component: _c2f9351c,
    name: "reservation-ticket-slug"
  }, {
    path: "/reservation/:slug",
    component: _2f9cfcef,
    name: "reservation-slug"
  }, {
    path: "/",
    component: _0b10dcba,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
