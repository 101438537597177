const state = () => ({
  form: {
    ticket_allotment_id: '',
    total_ticket: 1,
    ticket_allotment_type: '',
    ticket_allotment_date: '',
    ticket_allotment_total_price: 0,
    user_full_name: '',
    user_wa_number: '',
    user_email: '',
    user_sosmed_account: '',
    addon_items: [],
    payment_method: '',
    voucher_code: '',
    voucher_discount_nominal: 0,
    note: '',
    total_price: 0
  }
})

const mutations = {
  setForm (state, form) {
    state.form = form
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}