import firebase from 'firebase'

const config = {
  apiKey: "",
  authDomain: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: ""
}

if (!firebase.apps.length) { 
  firebase.initializeApp(config)
}

export const db = firebase.firestore()

export default (ctx, inject) => {
  inject('firebase', firebase)
}